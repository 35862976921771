[str-row] {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  -moz-box-sizing: border-box;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap; }

[str-row-vertical] {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -15em;
  margin-bottom: -15em;
  -moz-box-sizing: border-box;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-flex-wrap: wrap; }

[str-xs], [str-xs='1'], [str-xs='2'], [str-xs='3'], [str-xs='4'], [str-xs='5'], [str-xs='6'], [str-xs='7'], [str-xs='8'], [str-xs='9'], [str-xs='10'], [str-xs='11'], [str-xs='12'] {
  box-sizing: border-box;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -moz-box-sizing: border-box;
  -ms-flex-direction: column;
  -ms-flex-positive: 0;
  -ms-flex-negative: 0;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-flex-shrink: 0; }

[str-row-vertical] [str-xs], [str-row-vertical] [str-xs='1'], [str-row-vertical] [str-xs='2'], [str-row-vertical] [str-xs='3'], [str-row-vertical] [str-xs='4'], [str-row-vertical] [str-xs='5'], [str-row-vertical] [str-xs='6'], [str-row-vertical] [str-xs='7'], [str-row-vertical] [str-xs='8'], [str-row-vertical] [str-xs='9'], [str-row-vertical] [str-xs='10'], [str-row-vertical] [str-xs='11'], [str-row-vertical] [str-xs='12'] {
  padding-left: initial;
  padding-right: initial;
  padding-top: 15px;
  padding-bottom: 15px; }

[str-xs] {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0;
  -webkit-flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1; }

[str-row-vertical] [str-xs] {
  flex-basis: 0;
  flex-grow: 1;
  max-height: 100%;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0;
  -webkit-flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1; }

[str-xs='1'] {
  -webkit-flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

[str-xs='2'] {
  -webkit-flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[str-xs='3'] {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

[str-xs='4'] {
  -webkit-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[str-xs='5'] {
  -webkit-flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

[str-xs='6'] {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

[str-xs='7'] {
  -webkit-flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

[str-xs='8'] {
  -webkit-flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

[str-xs='9'] {
  -webkit-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

[str-xs='10'] {
  -webkit-flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

[str-xs='11'] {
  -webkit-flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

[str-xs='12'] {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

[str-row-vertical] [str-xs='1'] {
  max-width: initial;
  max-height: 8.33333%; }

[str-row-vertical] [str-xs='2'] {
  max-width: initial;
  max-height: 16.66667%; }

[str-row-vertical] [str-xs='3'] {
  max-width: initial;
  max-height: 25%; }

[str-row-vertical] [str-xs='4'] {
  max-width: initial;
  max-height: 33.33333%; }

[str-row-vertical] [str-xs='5'] {
  max-width: initial;
  max-height: 41.66667%; }

[str-row-vertical] [str-xs='6'] {
  max-width: initial;
  max-height: 50%; }

[str-row-vertical] [str-xs='7'] {
  max-width: initial;
  max-height: 58.33333%; }

[str-row-vertical] [str-xs='8'] {
  max-width: initial;
  max-height: 66.66667%; }

[str-row-vertical] [str-xs='9'] {
  max-width: initial;
  max-height: 75%; }

[str-row-vertical] [str-xs='10'] {
  max-width: initial;
  max-height: 83.33333%; }

[str-row-vertical] [str-xs='11'] {
  max-width: initial;
  max-height: 91.66667%; }

[str-row-vertical] [str-xs='12'] {
  max-width: initial;
  max-height: 100%; }

[str-xs-offset='1'] {
  margin-left: 8.33333%; }

[str-xs-offset='2'] {
  margin-left: 16.66667%; }

[str-xs-offset='3'] {
  margin-left: 25%; }

[str-xs-offset='4'] {
  margin-left: 33.33333%; }

[str-xs-offset='5'] {
  margin-left: 41.66667%; }

[str-xs-offset='6'] {
  margin-left: 50%; }

[str-xs-offset='7'] {
  margin-left: 58.33333%; }

[str-xs-offset='8'] {
  margin-left: 66.66667%; }

[str-xs-offset='9'] {
  margin-left: 75%; }

[str-xs-offset='10'] {
  margin-left: 83.33333%; }

[str-xs-offset='11'] {
  margin-left: 91.66667%; }

[str-row-vertical] [str-xs-offset='1'] {
  margin-left: intitial;
  margin-top: 8.33333%; }

[str-row-vertical] [str-xs-offset='2'] {
  margin-left: intitial;
  margin-top: 16.66667%; }

[str-row-vertical] [str-xs-offset='3'] {
  margin-left: intitial;
  margin-top: 25%; }

[str-row-vertical] [str-xs-offset='4'] {
  margin-left: intitial;
  margin-top: 33.33333%; }

[str-row-vertical] [str-xs-offset='5'] {
  margin-left: intitial;
  margin-top: 41.66667%; }

[str-row-vertical] [str-xs-offset='6'] {
  margin-left: intitial;
  margin-top: 50%; }

[str-row-vertical] [str-xs-offset='7'] {
  margin-left: intitial;
  margin-top: 58.33333%; }

[str-row-vertical] [str-xs-offset='8'] {
  margin-left: intitial;
  margin-top: 66.66667%; }

[str-row-vertical] [str-xs-offset='9'] {
  margin-left: intitial;
  margin-top: 75%; }

[str-row-vertical] [str-xs-offset='10'] {
  margin-left: intitial;
  margin-top: 83.33333%; }

[str-row-vertical] [str-xs-offset='11'] {
  margin-left: intitial;
  margin-top: 91.66667%; }

[str-xs-order='0'] {
  order: 0; }

[str-xs-order='1'] {
  order: 1; }

[str-xs-order='2'] {
  order: 2; }

[str-xs-order='3'] {
  order: 3; }

[str-xs-order='4'] {
  order: 4; }

[str-xs-order='5'] {
  order: 5; }

[str-xs-order='6'] {
  order: 6; }

[str-xs-order='7'] {
  order: 7; }

[str-xs-order='8'] {
  order: 8; }

[str-xs-order='9'] {
  order: 9; }

[str-xs-order='10'] {
  order: 10; }

[str-xs-order='11'] {
  order: 11; }

[str-xs-flex='0'], [str-row-vertical] [str-xs-flex='0'] {
  flex: 0; }

[str-xs-flex='1'], [str-row-vertical] [str-xs-flex='1'] {
  flex: 1; }

[str-xs-flex='2'], [str-row-vertical] [str-xs-flex='2'] {
  flex: 2; }

[str-xs-flex='3'], [str-row-vertical] [str-xs-flex='3'] {
  flex: 3; }

[str-xs-flex='4'], [str-row-vertical] [str-xs-flex='4'] {
  flex: 4; }

[str-xs-flex='5'], [str-row-vertical] [str-xs-flex='5'] {
  flex: 5; }

[str-xs-flex='6'], [str-row-vertical] [str-xs-flex='6'] {
  flex: 6; }

[str-xs-flex='7'], [str-row-vertical] [str-xs-flex='7'] {
  flex: 7; }

[str-xs-flex='8'], [str-row-vertical] [str-xs-flex='8'] {
  flex: 8; }

[str-xs-flex='9'], [str-row-vertical] [str-xs-flex='9'] {
  flex: 9; }

[str-xs-flex='10'], [str-row-vertical] [str-xs-flex='10'] {
  flex: 10; }

[str-xs-flex='11'], [str-row-vertical] [str-xs-flex='11'] {
  flex: 11; }

@media only screen and (min-width: 48em) {
  [str-sm], [str-sm='1'], [str-sm='2'], [str-sm='3'], [str-sm='4'], [str-sm='5'], [str-sm='6'], [str-sm='7'], [str-sm='8'], [str-sm='9'], [str-sm='10'], [str-sm='11'], [str-sm='12'] {
    box-sizing: border-box;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -moz-box-sizing: border-box;
    -ms-flex-direction: column;
    -ms-flex-positive: 0;
    -ms-flex-negative: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -webkit-flex-shrink: 0; }

  [str-row-vertical] [str-sm], [str-row-vertical] [str-sm='1'], [str-row-vertical] [str-sm='2'], [str-row-vertical] [str-sm='3'], [str-row-vertical] [str-sm='4'], [str-row-vertical] [str-sm='5'], [str-row-vertical] [str-sm='6'], [str-row-vertical] [str-sm='7'], [str-row-vertical] [str-sm='8'], [str-row-vertical] [str-sm='9'], [str-row-vertical] [str-sm='10'], [str-row-vertical] [str-sm='11'], [str-row-vertical] [str-sm='12'] {
    padding-left: initial;
    padding-right: initial;
    padding-top: 15px;
    padding-bottom: 15px; }

  [str-sm] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; }

  [str-row-vertical] [str-sm] {
    flex-basis: 0;
    flex-grow: 1;
    max-height: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; }

  [str-sm='1'] {
    -webkit-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

  [str-sm='2'] {
    -webkit-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

  [str-sm='3'] {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }

  [str-sm='4'] {
    -webkit-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

  [str-sm='5'] {
    -webkit-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

  [str-sm='6'] {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }

  [str-sm='7'] {
    -webkit-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

  [str-sm='8'] {
    -webkit-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

  [str-sm='9'] {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }

  [str-sm='10'] {
    -webkit-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

  [str-sm='11'] {
    -webkit-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

  [str-sm='12'] {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }

  [str-row-vertical] [str-sm='1'] {
    max-width: initial;
    max-height: 8.33333%; }

  [str-row-vertical] [str-sm='2'] {
    max-width: initial;
    max-height: 16.66667%; }

  [str-row-vertical] [str-sm='3'] {
    max-width: initial;
    max-height: 25%; }

  [str-row-vertical] [str-sm='4'] {
    max-width: initial;
    max-height: 33.33333%; }

  [str-row-vertical] [str-sm='5'] {
    max-width: initial;
    max-height: 41.66667%; }

  [str-row-vertical] [str-sm='6'] {
    max-width: initial;
    max-height: 50%; }

  [str-row-vertical] [str-sm='7'] {
    max-width: initial;
    max-height: 58.33333%; }

  [str-row-vertical] [str-sm='8'] {
    max-width: initial;
    max-height: 66.66667%; }

  [str-row-vertical] [str-sm='9'] {
    max-width: initial;
    max-height: 75%; }

  [str-row-vertical] [str-sm='10'] {
    max-width: initial;
    max-height: 83.33333%; }

  [str-row-vertical] [str-sm='11'] {
    max-width: initial;
    max-height: 91.66667%; }

  [str-row-vertical] [str-sm='12'] {
    max-width: initial;
    max-height: 100%; }

  [str-sm-offset='1'] {
    margin-left: 8.33333%; }

  [str-sm-offset='2'] {
    margin-left: 16.66667%; }

  [str-sm-offset='3'] {
    margin-left: 25%; }

  [str-sm-offset='4'] {
    margin-left: 33.33333%; }

  [str-sm-offset='5'] {
    margin-left: 41.66667%; }

  [str-sm-offset='6'] {
    margin-left: 50%; }

  [str-sm-offset='7'] {
    margin-left: 58.33333%; }

  [str-sm-offset='8'] {
    margin-left: 66.66667%; }

  [str-sm-offset='9'] {
    margin-left: 75%; }

  [str-sm-offset='10'] {
    margin-left: 83.33333%; }

  [str-sm-offset='11'] {
    margin-left: 91.66667%; }

  [str-row-vertical] [str-sm-offset='1'] {
    margin-left: intitial;
    margin-top: 8.33333%; }

  [str-row-vertical] [str-sm-offset='2'] {
    margin-left: intitial;
    margin-top: 16.66667%; }

  [str-row-vertical] [str-sm-offset='3'] {
    margin-left: intitial;
    margin-top: 25%; }

  [str-row-vertical] [str-sm-offset='4'] {
    margin-left: intitial;
    margin-top: 33.33333%; }

  [str-row-vertical] [str-sm-offset='5'] {
    margin-left: intitial;
    margin-top: 41.66667%; }

  [str-row-vertical] [str-sm-offset='6'] {
    margin-left: intitial;
    margin-top: 50%; }

  [str-row-vertical] [str-sm-offset='7'] {
    margin-left: intitial;
    margin-top: 58.33333%; }

  [str-row-vertical] [str-sm-offset='8'] {
    margin-left: intitial;
    margin-top: 66.66667%; }

  [str-row-vertical] [str-sm-offset='9'] {
    margin-left: intitial;
    margin-top: 75%; }

  [str-row-vertical] [str-sm-offset='10'] {
    margin-left: intitial;
    margin-top: 83.33333%; }

  [str-row-vertical] [str-sm-offset='11'] {
    margin-left: intitial;
    margin-top: 91.66667%; }

  [str-sm-order='0'] {
    order: 0; }

  [str-sm-order='1'] {
    order: 1; }

  [str-sm-order='2'] {
    order: 2; }

  [str-sm-order='3'] {
    order: 3; }

  [str-sm-order='4'] {
    order: 4; }

  [str-sm-order='5'] {
    order: 5; }

  [str-sm-order='6'] {
    order: 6; }

  [str-sm-order='7'] {
    order: 7; }

  [str-sm-order='8'] {
    order: 8; }

  [str-sm-order='9'] {
    order: 9; }

  [str-sm-order='10'] {
    order: 10; }

  [str-sm-order='11'] {
    order: 11; }

  [str-sm-flex='0'], [str-row-vertical] [str-sm-flex='0'] {
    flex: 0; }

  [str-sm-flex='1'], [str-row-vertical] [str-sm-flex='1'] {
    flex: 1; }

  [str-sm-flex='2'], [str-row-vertical] [str-sm-flex='2'] {
    flex: 2; }

  [str-sm-flex='3'], [str-row-vertical] [str-sm-flex='3'] {
    flex: 3; }

  [str-sm-flex='4'], [str-row-vertical] [str-sm-flex='4'] {
    flex: 4; }

  [str-sm-flex='5'], [str-row-vertical] [str-sm-flex='5'] {
    flex: 5; }

  [str-sm-flex='6'], [str-row-vertical] [str-sm-flex='6'] {
    flex: 6; }

  [str-sm-flex='7'], [str-row-vertical] [str-sm-flex='7'] {
    flex: 7; }

  [str-sm-flex='8'], [str-row-vertical] [str-sm-flex='8'] {
    flex: 8; }

  [str-sm-flex='9'], [str-row-vertical] [str-sm-flex='9'] {
    flex: 9; }

  [str-sm-flex='10'], [str-row-vertical] [str-sm-flex='10'] {
    flex: 10; }

  [str-sm-flex='11'], [str-row-vertical] [str-sm-flex='11'] {
    flex: 11; } }
@media only screen and (min-width: 62em) {
  [str-md], [str-md='1'], [str-md='2'], [str-md='3'], [str-md='4'], [str-md='5'], [str-md='6'], [str-md='7'], [str-md='8'], [str-md='9'], [str-md='10'], [str-md='11'], [str-md='12'] {
    box-sizing: border-box;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -moz-box-sizing: border-box;
    -ms-flex-direction: column;
    -ms-flex-positive: 0;
    -ms-flex-negative: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -webkit-flex-shrink: 0; }

  [str-row-vertical] [str-md], [str-row-vertical] [str-md='1'], [str-row-vertical] [str-md='2'], [str-row-vertical] [str-md='3'], [str-row-vertical] [str-md='4'], [str-row-vertical] [str-md='5'], [str-row-vertical] [str-md='6'], [str-row-vertical] [str-md='7'], [str-row-vertical] [str-md='8'], [str-row-vertical] [str-md='9'], [str-row-vertical] [str-md='10'], [str-row-vertical] [str-md='11'], [str-row-vertical] [str-md='12'] {
    padding-left: initial;
    padding-right: initial;
    padding-top: 15px;
    padding-bottom: 15px; }

  [str-md] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; }

  [str-row-vertical] [str-md] {
    flex-basis: 0;
    flex-grow: 1;
    max-height: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; }

  [str-md='1'] {
    -webkit-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

  [str-md='2'] {
    -webkit-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

  [str-md='3'] {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }

  [str-md='4'] {
    -webkit-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

  [str-md='5'] {
    -webkit-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

  [str-md='6'] {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }

  [str-md='7'] {
    -webkit-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

  [str-md='8'] {
    -webkit-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

  [str-md='9'] {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }

  [str-md='10'] {
    -webkit-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

  [str-md='11'] {
    -webkit-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

  [str-md='12'] {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }

  [str-row-vertical] [str-md='1'] {
    max-width: initial;
    max-height: 8.33333%; }

  [str-row-vertical] [str-md='2'] {
    max-width: initial;
    max-height: 16.66667%; }

  [str-row-vertical] [str-md='3'] {
    max-width: initial;
    max-height: 25%; }

  [str-row-vertical] [str-md='4'] {
    max-width: initial;
    max-height: 33.33333%; }

  [str-row-vertical] [str-md='5'] {
    max-width: initial;
    max-height: 41.66667%; }

  [str-row-vertical] [str-md='6'] {
    max-width: initial;
    max-height: 50%; }

  [str-row-vertical] [str-md='7'] {
    max-width: initial;
    max-height: 58.33333%; }

  [str-row-vertical] [str-md='8'] {
    max-width: initial;
    max-height: 66.66667%; }

  [str-row-vertical] [str-md='9'] {
    max-width: initial;
    max-height: 75%; }

  [str-row-vertical] [str-md='10'] {
    max-width: initial;
    max-height: 83.33333%; }

  [str-row-vertical] [str-md='11'] {
    max-width: initial;
    max-height: 91.66667%; }

  [str-row-vertical] [str-md='12'] {
    max-width: initial;
    max-height: 100%; }

  [str-md-offset='1'] {
    margin-left: 8.33333%; }

  [str-md-offset='2'] {
    margin-left: 16.66667%; }

  [str-md-offset='3'] {
    margin-left: 25%; }

  [str-md-offset='4'] {
    margin-left: 33.33333%; }

  [str-md-offset='5'] {
    margin-left: 41.66667%; }

  [str-md-offset='6'] {
    margin-left: 50%; }

  [str-md-offset='7'] {
    margin-left: 58.33333%; }

  [str-md-offset='8'] {
    margin-left: 66.66667%; }

  [str-md-offset='9'] {
    margin-left: 75%; }

  [str-md-offset='10'] {
    margin-left: 83.33333%; }

  [str-md-offset='11'] {
    margin-left: 91.66667%; }

  [str-row-vertical] [str-md-offset='1'] {
    margin-left: intitial;
    margin-top: 8.33333%; }

  [str-row-vertical] [str-md-offset='2'] {
    margin-left: intitial;
    margin-top: 16.66667%; }

  [str-row-vertical] [str-md-offset='3'] {
    margin-left: intitial;
    margin-top: 25%; }

  [str-row-vertical] [str-md-offset='4'] {
    margin-left: intitial;
    margin-top: 33.33333%; }

  [str-row-vertical] [str-md-offset='5'] {
    margin-left: intitial;
    margin-top: 41.66667%; }

  [str-row-vertical] [str-md-offset='6'] {
    margin-left: intitial;
    margin-top: 50%; }

  [str-row-vertical] [str-md-offset='7'] {
    margin-left: intitial;
    margin-top: 58.33333%; }

  [str-row-vertical] [str-md-offset='8'] {
    margin-left: intitial;
    margin-top: 66.66667%; }

  [str-row-vertical] [str-md-offset='9'] {
    margin-left: intitial;
    margin-top: 75%; }

  [str-row-vertical] [str-md-offset='10'] {
    margin-left: intitial;
    margin-top: 83.33333%; }

  [str-row-vertical] [str-md-offset='11'] {
    margin-left: intitial;
    margin-top: 91.66667%; }

  [str-md-order='0'] {
    order: 0; }

  [str-md-order='1'] {
    order: 1; }

  [str-md-order='2'] {
    order: 2; }

  [str-md-order='3'] {
    order: 3; }

  [str-md-order='4'] {
    order: 4; }

  [str-md-order='5'] {
    order: 5; }

  [str-md-order='6'] {
    order: 6; }

  [str-md-order='7'] {
    order: 7; }

  [str-md-order='8'] {
    order: 8; }

  [str-md-order='9'] {
    order: 9; }

  [str-md-order='10'] {
    order: 10; }

  [str-md-order='11'] {
    order: 11; }

  [str-md-flex='0'], [str-row-vertical] [str-md-flex='0'] {
    flex: 0; }

  [str-md-flex='1'], [str-row-vertical] [str-md-flex='1'] {
    flex: 1; }

  [str-md-flex='2'], [str-row-vertical] [str-md-flex='2'] {
    flex: 2; }

  [str-md-flex='3'], [str-row-vertical] [str-md-flex='3'] {
    flex: 3; }

  [str-md-flex='4'], [str-row-vertical] [str-md-flex='4'] {
    flex: 4; }

  [str-md-flex='5'], [str-row-vertical] [str-md-flex='5'] {
    flex: 5; }

  [str-md-flex='6'], [str-row-vertical] [str-md-flex='6'] {
    flex: 6; }

  [str-md-flex='7'], [str-row-vertical] [str-md-flex='7'] {
    flex: 7; }

  [str-md-flex='8'], [str-row-vertical] [str-md-flex='8'] {
    flex: 8; }

  [str-md-flex='9'], [str-row-vertical] [str-md-flex='9'] {
    flex: 9; }

  [str-md-flex='10'], [str-row-vertical] [str-md-flex='10'] {
    flex: 10; }

  [str-md-flex='11'], [str-row-vertical] [str-md-flex='11'] {
    flex: 11; } }
@media only screen and (min-width: 65em) {
  [str-lg], [str-lg='1'], [str-lg='2'], [str-lg='3'], [str-lg='4'], [str-lg='5'], [str-lg='6'], [str-lg='7'], [str-lg='8'], [str-lg='9'], [str-lg='10'], [str-lg='11'], [str-lg='12'] {
    box-sizing: border-box;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -moz-box-sizing: border-box;
    -ms-flex-direction: column;
    -ms-flex-positive: 0;
    -ms-flex-negative: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -webkit-flex-shrink: 0; }

  [str-row-vertical] [str-lg], [str-row-vertical] [str-lg='1'], [str-row-vertical] [str-lg='2'], [str-row-vertical] [str-lg='3'], [str-row-vertical] [str-lg='4'], [str-row-vertical] [str-lg='5'], [str-row-vertical] [str-lg='6'], [str-row-vertical] [str-lg='7'], [str-row-vertical] [str-lg='8'], [str-row-vertical] [str-lg='9'], [str-row-vertical] [str-lg='10'], [str-row-vertical] [str-lg='11'], [str-row-vertical] [str-lg='12'] {
    padding-left: initial;
    padding-right: initial;
    padding-top: 15px;
    padding-bottom: 15px; }

  [str-lg] {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; }

  [str-row-vertical] [str-lg] {
    flex-basis: 0;
    flex-grow: 1;
    max-height: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; }

  [str-lg='1'] {
    -webkit-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }

  [str-lg='2'] {
    -webkit-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }

  [str-lg='3'] {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }

  [str-lg='4'] {
    -webkit-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }

  [str-lg='5'] {
    -webkit-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }

  [str-lg='6'] {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }

  [str-lg='7'] {
    -webkit-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }

  [str-lg='8'] {
    -webkit-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }

  [str-lg='9'] {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }

  [str-lg='10'] {
    -webkit-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }

  [str-lg='11'] {
    -webkit-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }

  [str-lg='12'] {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }

  [str-row-vertical] [str-lg='1'] {
    max-width: initial;
    max-height: 8.33333%; }

  [str-row-vertical] [str-lg='2'] {
    max-width: initial;
    max-height: 16.66667%; }

  [str-row-vertical] [str-lg='3'] {
    max-width: initial;
    max-height: 25%; }

  [str-row-vertical] [str-lg='4'] {
    max-width: initial;
    max-height: 33.33333%; }

  [str-row-vertical] [str-lg='5'] {
    max-width: initial;
    max-height: 41.66667%; }

  [str-row-vertical] [str-lg='6'] {
    max-width: initial;
    max-height: 50%; }

  [str-row-vertical] [str-lg='7'] {
    max-width: initial;
    max-height: 58.33333%; }

  [str-row-vertical] [str-lg='8'] {
    max-width: initial;
    max-height: 66.66667%; }

  [str-row-vertical] [str-lg='9'] {
    max-width: initial;
    max-height: 75%; }

  [str-row-vertical] [str-lg='10'] {
    max-width: initial;
    max-height: 83.33333%; }

  [str-row-vertical] [str-lg='11'] {
    max-width: initial;
    max-height: 91.66667%; }

  [str-row-vertical] [str-lg='12'] {
    max-width: initial;
    max-height: 100%; }

  [str-lg-offset='1'] {
    margin-left: 8.33333%; }

  [str-lg-offset='2'] {
    margin-left: 16.66667%; }

  [str-lg-offset='3'] {
    margin-left: 25%; }

  [str-lg-offset='4'] {
    margin-left: 33.33333%; }

  [str-lg-offset='5'] {
    margin-left: 41.66667%; }

  [str-lg-offset='6'] {
    margin-left: 50%; }

  [str-lg-offset='7'] {
    margin-left: 58.33333%; }

  [str-lg-offset='8'] {
    margin-left: 66.66667%; }

  [str-lg-offset='9'] {
    margin-left: 75%; }

  [str-lg-offset='10'] {
    margin-left: 83.33333%; }

  [str-lg-offset='11'] {
    margin-left: 91.66667%; }

  [str-row-vertical] [str-lg-offset='1'] {
    margin-left: intitial;
    margin-top: 8.33333%; }

  [str-row-vertical] [str-lg-offset='2'] {
    margin-left: intitial;
    margin-top: 16.66667%; }

  [str-row-vertical] [str-lg-offset='3'] {
    margin-left: intitial;
    margin-top: 25%; }

  [str-row-vertical] [str-lg-offset='4'] {
    margin-left: intitial;
    margin-top: 33.33333%; }

  [str-row-vertical] [str-lg-offset='5'] {
    margin-left: intitial;
    margin-top: 41.66667%; }

  [str-row-vertical] [str-lg-offset='6'] {
    margin-left: intitial;
    margin-top: 50%; }

  [str-row-vertical] [str-lg-offset='7'] {
    margin-left: intitial;
    margin-top: 58.33333%; }

  [str-row-vertical] [str-lg-offset='8'] {
    margin-left: intitial;
    margin-top: 66.66667%; }

  [str-row-vertical] [str-lg-offset='9'] {
    margin-left: intitial;
    margin-top: 75%; }

  [str-row-vertical] [str-lg-offset='10'] {
    margin-left: intitial;
    margin-top: 83.33333%; }

  [str-row-vertical] [str-lg-offset='11'] {
    margin-left: intitial;
    margin-top: 91.66667%; }

  [str-lg-order='0'] {
    order: 0; }

  [str-lg-order='1'] {
    order: 1; }

  [str-lg-order='2'] {
    order: 2; }

  [str-lg-order='3'] {
    order: 3; }

  [str-lg-order='4'] {
    order: 4; }

  [str-lg-order='5'] {
    order: 5; }

  [str-lg-order='6'] {
    order: 6; }

  [str-lg-order='7'] {
    order: 7; }

  [str-lg-order='8'] {
    order: 8; }

  [str-lg-order='9'] {
    order: 9; }

  [str-lg-order='10'] {
    order: 10; }

  [str-lg-order='11'] {
    order: 11; }

  [str-lg-flex='0'], [str-row-vertical] [str-lg-flex='0'] {
    flex: 0; }

  [str-lg-flex='1'], [str-row-vertical] [str-lg-flex='1'] {
    flex: 1; }

  [str-lg-flex='2'], [str-row-vertical] [str-lg-flex='2'] {
    flex: 2; }

  [str-lg-flex='3'], [str-row-vertical] [str-lg-flex='3'] {
    flex: 3; }

  [str-lg-flex='4'], [str-row-vertical] [str-lg-flex='4'] {
    flex: 4; }

  [str-lg-flex='5'], [str-row-vertical] [str-lg-flex='5'] {
    flex: 5; }

  [str-lg-flex='6'], [str-row-vertical] [str-lg-flex='6'] {
    flex: 6; }

  [str-lg-flex='7'], [str-row-vertical] [str-lg-flex='7'] {
    flex: 7; }

  [str-lg-flex='8'], [str-row-vertical] [str-lg-flex='8'] {
    flex: 8; }

  [str-lg-flex='9'], [str-row-vertical] [str-lg-flex='9'] {
    flex: 9; }

  [str-lg-flex='10'], [str-row-vertical] [str-lg-flex='10'] {
    flex: 10; }

  [str-lg-flex='11'], [str-row-vertical] [str-lg-flex='11'] {
    flex: 11; } }

/*# sourceMappingURL=structure.css.map */
