/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}
/* --------------

CUSTOM STYLES

---------------*/
.owl-controls {
    bottom: 0;
    position: absolute;
    width: 100%;
}
/*
.owl-controls {
    top: 0;
    position: relative;
    width: 100%;
}
*/
.owl-controls.clickable {
    cursor: default;
}
.owl-controls .owl-dots,
.owl-controls .owl-pagination {
    margin-bottom: 0;
    text-align: center;
    padding-top: 0;
    box-sizing: content-box;
}
.owl-controls .owl-dots .owl-dot,
.owl-controls .owl-pagination .owl-dot,
.owl-controls .owl-dots .owl-page,
.owl-controls .owl-pagination .owl-page {
    padding: 9px;
    display: inline-block;
    cursor: pointer;
    box-sizing: content-box;
}
.owl-controls .owl-dots .owl-dot span,
.owl-controls .owl-pagination .owl-dot span,
.owl-controls .owl-dots .owl-page span,
.owl-controls .owl-pagination .owl-page span {
    width: 15px;
    height: 15px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    border-radius: 30px;
    cursor: pointer;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
}
.owl-controls .owl-dots .owl-dot.active,
.owl-controls .owl-pagination .owl-dot.active,
.owl-controls .owl-dots .owl-page.active,
.owl-controls .owl-pagination .owl-page.active {
    cursor: default;
}
.owl-controls .owl-dots .owl-dot.active span,
.owl-controls .owl-pagination .owl-dot.active span,
.owl-controls .owl-dots .owl-page.active span,
.owl-controls .owl-pagination .owl-page.active span {
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
}
.owl-controls .owl-prev,
.owl-controls .owl-next {
    display: inline-block;
    opacity: 0;
    cursor: pointer;
    color: #951b22;
    font-size: 1em;
    position: relative;
    top: -5px;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}
.owl-nav{
    text-align: center;
    top:-40%;
}
.owl-theme .owl-controls .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 6px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 0px;
}
.owl-controls:hover .owl-prev,
.owl-stage-outer:hover + .owl-controls .owl-prev,
.owl-controls:hover .owl-next,
.owl-stage-outer:hover + .owl-controls .owl-next {
    opacity: 1;
}
.module-slider .owl-stage-outer,
.module-slider .owl-stage {
    height: 100%;
}
.module-slider .owl-stage-outer > li,
.module-slider .owl-stage > li {
    height: 100%;
}
.module-slider .owl-drag .owl-item {
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
}
.module-slider .owl-item {
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}
.module-slider .owl-item.active {
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
}
.module-slider .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
    z-index: 0;
}
.owl-carousel .owl-animated-out {
    z-index: 1;
}
.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
    -webkit-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}
/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}
.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
}
/*.owl-carousel .owl-controls .owl-nav .owl-prev,*/
/*.owl-carousel .owl-controls .owl-nav .owl-next,*/
/*.owl-carousel .owl-controls .owl-dot {*/
    /*cursor: pointer;*/
    /*cursor: hand;*/
    /*-webkit-user-select: none;*/
    /*-khtml-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
/*}*/
.owl-carousel.owl-loaded {
    display: block;
}
.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}
.owl-carousel.owl-hidden {
    opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
    display: none;
}
.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}
.owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}
.owl-carousel .owl-rtl {
    direction: rtl;
}
.owl-carousel .owl-rtl .owl-item {
    float: right;
}
/* No Js */
.no-js .owl-carousel {
    display: block;
}
/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
/*
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
}
.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale 100ms ease;
    transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
    -webkit-transition: scale(1.3, 1.3);
    transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}
.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
}
